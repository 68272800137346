import { useEffect, useState } from "react";
import styled from "styled-components";
import useApplication from "../hooks/useApplication";
const StyledForm = styled.form`
  max-width: 60rem;
  padding: 0rem 2.5rem;
  margin: 2rem auto 4rem auto;
  p {
    text-align: center;
    font-size: 1.4rem;
    a {
      font-size: 1.4rem;
      color: #000;
    }
  }
  h1 {
    text-align: center;
  }
  article,
  fieldset {
    display: block;
  }
  .flex {
    gap: 2.5rem;
    display: flex;
    margin: 2.5rem auto;
    & > div {
      flex: 1;
      label {
        display: block;
        font-size: 1.3rem;
        margin-bottom: 0.5rem;
      }
      input {
        width: 100%;
      }
    }
  }
  fieldset {
    margin: 1.5rem auto;
    & > div {
      margin: 1.5rem 0rem;
    }
  }
  input {
    padding: 1rem;
    box-sizing: border-box;
    font-family: ${(props) => props.theme.fonts.main};
  }
  input[type="submit"] {
    padding: 1rem 2rem;
    border: 0.1rem solid #666;
    background: ${(props) => props.theme.colors.primary};
    color: #fff;
    font-weight: bold;
    font-family: ${(props) => props.theme.fonts.main};
    &:disabled {
      background: none;
      border: none;
      color: green;
      padding: 0rem;
      margin: 0rem;
      font-size: 1.6rem;
    }
  }
`;

const StyledButton = styled.div`
  margin: 0 auto;
  display: block;
  text-align: center;
  padding: 0.5rem;
  margin-bottom: 5rem;
  p {
    font-size: 2.1rem;
    margin-bottom: 3rem;
  }
  a {
    color: #fff;
    font-weight: bold;
    font-size: 2.4rem;
    border-radius: 1rem;
    padding: 2rem 2.5rem;
    text-decoration: none;
    white-space: nowrap;
    background: ${(props) => props.theme.colors.primary};
  }
`;

const StyledApplication = styled.div`
  background: "#fff !important";
`;

enum state {
  default,
  loading,
  success,
  error,
}

const Application = () => {
  const [fullName, setFullName] = useState<string>();
  const [dob, setDob] = useState<string>();
  const [phone, setPhone] = useState<number>();
  const [email, setEmail] = useState<string>();
  const [homeAddress, setHomeAddress] = useState<string>();
  // const [emergencyName, setEmergencyName] = useState<string>()
  // const [emergencyContact, setEmergencyContact] = useState<number>()
  const [location, setLocation] = useState<string[]>([]);
  const [shift, setShift] = useState<string[]>([]);
  const [availability, setAvailability] = useState<string[]>([]);
  const [apiState, setApiState] = useState<state>(state.default);
  const [message, setMessage] = useState<string>();
  const [refName1, setRefName1] = useState<string>();
  const [refPhone1, setRefPhone1] = useState<number>();
  const [refCompany1, setRefCompany1] = useState<string>();
  const [refName2, setRefName2] = useState<string>();
  const [refPhone2, setRefPhone2] = useState<number>();
  const [refCompany2, setRefCompany2] = useState<string>();
  const [refName3, setRefName3] = useState<string>();
  const [refPhone3, setRefPhone3] = useState<number>();
  const [refCompany3, setRefCompany3] = useState<string>();
  const [attachment, setAttachment] = useState<File>();

  const arrayHandler = (
    state: string[],
    setState: React.Dispatch<React.SetStateAction<string[]>>,
    value: string
  ) => {
    if (state.some((values) => values === value)) {
      const newState = state.filter((states: string) => states !== value);
      setState(newState);
    } else {
      setState((states) => [...states, value]);
    }
  };

  // TODO: clean up CSS

  const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setApiState(state.loading);

    // const formData = new FormData()
    // formData.append("resume", attachment!)
    // formData.append("name", fullName!)
    // formData.append("dob", dob!)
    // formData.append("location", location!)
    // formData.append("shift", shift!)
    // formData.append("email", email!)
    // formData.append("phone", phone!)
    // formData.append("homeAddress", homeAddress!)

    const payload = {
      name: fullName,
      dob,
      location,
      shift,
      email,
      phone,
      homeAddress,
      availability,
      refName1,
      refPhone1,
      refCompany1,
      refName2,
      refPhone2,
      refCompany2,
      refName3,
      refPhone3,
      refCompany3,
      // attachment: formData,
    };

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    };
    // https://nielsens-nodemailer.vercel.app/send
    fetch("https://nielsens-nodemailer.vercel.app/send", options)
      .then((response) => response.json())
      .then((data) => {
        setApiState(state.success);
        setMessage(data.msg);
      })
      .catch((error) => {
        console.error("Error:", error);
        setApiState(state.error);
        setMessage(
          "Sorry, there was an error. Please try again later or contact the web adminstrator."
        );
      });
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://form.jotform.com/jsform/230575299949071";
    script.async = true;
    const application = document.getElementById("application");
    application!.appendChild(script);

    return () => {
      application!.removeChild(script);
    };
  }, []);

  const { mailString } = useApplication();

  return (
    <StyledButton>
      <p>Want to be part of the Nielsens team?</p>
      <StyledApplication id="application" />
      {/* <a href="javascript:void( window.open( 'https://form.jotform.com/230576093298162', 'blank', 'scrollbars=yes, toolbar=no, width=700, height=500' ) ) "> Apply Now</a> */}
    </StyledButton>
  );
};

export default Application;
