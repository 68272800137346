import styled from "styled-components";
import wave from "../static/assets/wave.webp";
import contactUs from "../static/assets/title-contact.webp";
import map from "../static/assets/map.webp";
import logo from "../static/assets/logo.webp";
import { routes } from "../data/routes";
import { BsTelephone } from "react-icons/bs";
import { HiOutlineMail } from "react-icons/hi";
import { meta } from "../data/meta";
import { Social } from "./Header";
import useWindowSize from "../hooks/useWindowSize";

const StyledContainer = styled.div`
  width: 100%;
  height: auto;
  overflow: hidden;
  .wave {
    width: 110%;
    height: auto;
    display: block;
    position: relative;
    bottom: -0.5rem;
    left: -1rem;
  }
  footer {
    background: #000;
    .content {
      max-width: 90rem;
      margin: 0 auto;
      height: auto;
      color: #fff;
      padding: 3rem 3rem 10rem 3rem;
      text-align: center;
      .disclaimer {
        font-size: 1.4rem;
        span {
          font-size: 1.3rem;
          color: #ccc;
          white-space: nowrap;
          a {
            font-size: 1.3rem;
            color: #ccc;
            text-decoration: none;
          }
        }
      }
      .contact {
        max-width: 24rem;
      }
      .flex {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        padding: 5rem 0rem;
        & > div {
          flex: 1;
          .logo {
            max-width: 20rem;
            height: auto;
          }
          p {
            color: #fff;
          }
          a {
            color: #ccc;
            padding: 0rem 1rem;
            text-decoration: none;
            white-space: nowrap;
            transition: all 0.25s ease-in-out;
            &:hover {
              color: #fff;
            }
            svg {
              width: 1.3rem;
              position: relative;
              top: 0.3rem;
              padding-right: 1rem;
            }
          }
        }
        @media screen and (max-width: 740px) {
          display: block;
          margin: 0 auto;
          & > div {
            margin-bottom: 5rem;
            &:nth-child(2) {
              margin-bottom: 0rem;
            }
          }
        }
      }
      ul {
        list-style: none;
        margin: 0rem;
        padding: 3rem 0rem;
        li {
          display: inline;
          padding: 0rem 1.5rem;
          @media screen and (max-width: 500px) {
            display: block;
            padding: 1rem 0rem;
          }
          a {
            text-decoration: none;
            text-transform: uppercase;
            color: #ccc;
            transition: all 0.25s ease-in-out;
            &:hover {
              color: #fff;
            }
          }
        }
      }
    }
    .locations-container {
      gap: 7.5rem;
      padding: 3rem;
      display: flex;
      margin: 0 auto;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: center;
      & > div {
        text-align: center;
        max-width: 25rem;
        h3 {
          color: #fff;
          font-size: 3.5rem;
          margin: 0rem 0rem 1rem 0rem;
          font-family: ${(props) => props.theme.fonts.script};
          &.smaller {
            font-size: 2.8rem;
          }
        }
        a,
        p {
          margin: 0;
          color: #fff;
          text-decoration: none;
        }
      }
    }
  }
`;

const Footer = () => {
  const year = new Date().getFullYear();
  const window = useWindowSize();
  return (
    <StyledContainer>
      {/* <img src={wave} className="wave" alt="Nielsen's Frozen Custard" /> */}
      <footer>
        <div className="locations-container">
          <div>
            <h3>Sansone Park Place</h3>
            <a
              target="_blank"
              href="https://www.google.com/maps/place/Nielsen's+Frozen+Custard/@36.017543,-115.1198858,17z/data=!3m2!4b1!5s0x80c8ce3f5bf81c97:0xdd11eecbbd5c325e!4m5!3m4!1s0x80c8ce3f673068d9:0x237927bb9dc51985!8m2!3d36.017539!4d-115.1177165"
            >
              9480 S Eastern Ave #100
              <br />
              Las Vegas, NV 89123
              <br />
            </a>
            <a href="tel:7024514711">702.451.4711</a>
          </div>
          <div>
            <h3 className="smaller">Las Vegas Santa Fe Casino (Food Court)</h3>
            <a
              target="_blank"
              href="https://www.google.com/maps/place/4949+N+Rancho+Dr,+Las+Vegas,+NV+89130/@36.2506547,-115.2479977,17z/data=!3m1!4b1!4m6!3m5!1s0x80c8eb02f5d5cc17:0x3237e66951e5371b!8m2!3d36.2506504!4d-115.2454174!16s%2Fg%2F11c3q3shyy"
            >
              4949 N Rancho Dr,
              <br />
              Las Vegas, NV 89130
              <br />
            </a>
            <a href="tel:7022023384">702.202.3384</a>
          </div>
          <div>
            <h3>Las Vegas Red Rock Casino</h3>
            <a
              target="_blank"
              href="https://www.google.com/maps/place/Nielsen's+Frozen+Custard/@36.017543,-115.1198858,17z/data=!3m2!4b1!5s0x80c8ce3f5bf81c97:0xdd11eecbbd5c325e!4m5!3m4!1s0x80c8ce3f673068d9:0x237927bb9dc51985!8m2!3d36.017539!4d-115.1177165"
            >
              11101 W Charleston Blvd,
              <br />
              Las Vegas, NV 89135
              <br />
            </a>
            <a href="tel:7027493266">702.749.3266</a>
          </div>
          <div>
            <h3>Durango Casino</h3>
            <a
              target="_blank"
              href="https://www.google.com/maps/place/Durango+Casino+and+Resort/@36.0867329,-115.3233087,13z/data=!4m10!1m2!2m1!1sdurango+station!3m6!1s0x80c8c75b2314da4f:0x86f713693ad0d566!8m2!3d36.063495!4d-115.283024!15sCg9kdXJhbmdvIHN0YXRpb25aESIPZHVyYW5nbyBzdGF0aW9ukgEMcmVzb3J0X2hvdGVsmgEkQ2hkRFNVaE5NRzluUzBWSlEwRm5TVVJLTlhGRFRYTlJSUkFC4AEA!16s%2Fg%2F11tp6vgbms?entry=ttu"
            >
              6915 S Durango Dr
              <br />
              Las Vegas, NV 89148
              <br />
            </a>
          </div>
          <div>
            <h3>Bountiful</h3>
            <a
              target="_blank"
              href="https://www.google.com/maps/place/Nielsen's+Frozen+Custard/@40.8618805,-111.8965279,17z/data=!3m1!4b1!4m5!3m4!1s0x8752f7a4db786a35:0x85adf00bf0b40791!8m2!3d40.8617823!4d-111.8943401?hl=en"
            >
              570 W 2600 S<br />
              Bountiful, UT 84010
            </a>
          </div>
          <div>
            <h3>South Jordan</h3>
            <a
              target="_blank"
              href="https://www.google.com/maps?q=nielsens+south+jordan&sxsrf=ALeKk03p0PsFMg-6uhArEijzAGQWAt9eaw:1623204401803&iflsig=AINFCbYAAAAAYMAwQTFh-VnlMFR0VuY5RdmA78aa9vf0&gs_lcp=Cgdnd3Mtd2l6EAMyBAgjECcyCAgAEBYQChAeMgYIABAWEB4yBggAEBYQHjIGCAAQFhAeMgYIABAWEB4yCAgAEBYQChAeMgIIJjoFCAAQkQI6CAguELEDEIMBOggIABCxAxCDAToFCAAQsQM6BAgAEEM6CgguEMcBEK8BEEM6CwguELEDEMcBEKMCOgcIABCxAxBDOgcIABDJAxBDOgUIABCSAzoHCAAQhwIQFDoNCC4QhwIQxwEQrwEQFDoICC4QxwEQrwE6BAgAEAo6AggAOgoILhDHARCvARAKOgoIABCxAxCDARAKUO4LWKwtYIMvaAFwAHgAgAGGAYgBhBKSAQUxMC4xMpgBAKABAaoBB2d3cy13aXo&uact=5&um=1&ie=UTF-8&sa=X&ved=2ahUKEwj39ZvRu4nxAhVXV80KHVq0CocQ_AUoAnoECAIQBA"
            >
              3779 S Jordan Pkwy
              <br />
              South Jordan, UT 84009
            </a>
          </div>
          <div>
            <h3>Holladay</h3>
            <a
              target="_blank"
              href="https://www.google.com/maps/place/Nielsen's+Frozen+Custard/@40.686637,-111.8473911,17z/data=!3m1!4b1!4m5!3m4!1s0x875261dd2d4ef89b:0x9a6d0f3e2f7d7153!8m2!3d40.6866311!4d-111.8452563?hl=en"
            >
              3918 Highland Dr
              <br />
              Holladay, UT 84124
            </a>
          </div>
          <div>
            <h3>St. George</h3>
            <a
              target="_blank"
              href="https://www.google.com/maps/place/Nielsen's+Frozen+Custard/@37.1101564,-113.5761083,17z/data=!3m1!4b1!4m5!3m4!1s0x80ca44cd58bc765b:0xec0cfd611762879c!8m2!3d37.1100146!4d-113.5738595"
            >
              445 St George Blvd #102
              <br />
              St. George, UT 84770
            </a>
          </div>
          <div>
            <h3>Layton</h3>
            <a
              target="_blank"
              href="https://www.google.com/maps/place/Nielsen's+Frozen+Custard/@41.0657128,-111.973168,17z/data=!3m2!4b1!5s0x875303cb434a59c5:0x4ffc1060beb52689!4m5!3m4!1s0x875303cb41722b3b:0x4672e6350fa4bbc1!8m2!3d41.0657158!4d-111.9709813"
            >
              378 N Main St STE 2<br />
              Layton, UT 84041
            </a>
          </div>
        </div>
        <div className="content">
          <img
            src={contactUs}
            className="contact"
            alt="Nielsen's Frozen Custard"
          />
          <ul>
            {routes.map((route) => {
              return (
                <li key={route.title}>
                  <a href={route.link}>{route.title}</a>
                </li>
              );
            })}
          </ul>
          <Social white />
          <div className="flex">
            <div>
              <img src={map} alt="Nielsen's Frozen Custard" />
              <p>9480 S. Eastern Ave #100 Las Vegas, NV 89123</p>
              <a href={`tel:${meta[0].telephoneRaw}`}>
                <BsTelephone />
                {meta[0].telephone}
              </a>
              {window.width && window.width < 540 && <br />}
              <a href={`mailto:${meta[0].email}`}>
                <HiOutlineMail />
                {meta[0].email}
              </a>
            </div>
            <div>
              <img src={logo} className="logo" alt="Nielsen's Frozen Custard" />
              <p>
                Store Hours
                <br />
                <br />
                12PM - 10PM Everyday
              </p>
            </div>
          </div>
          <p className="disclaimer">
            &copy; {year} Nielsen's Frozen Custard. All Rights Reserved.{" "}
            <span>
              Made by{" "}
              <a href="https://kanvas.agency" target="_blank" rel="noreferrer">
                Kanvas
              </a>
            </span>
          </p>
        </div>
      </footer>
    </StyledContainer>
  );
};

export default Footer;
