import styled from "styled-components";
import useApplication from "../hooks/useApplication";
import background from "../static/assets/main-bg.webp";

const StyledContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  .content {
    .banner {
      position: absolute;
      padding: 1rem 3rem 3rem 3rem;
      top: 50%;
      left: 50%;
      border-radius: 1rem;
      background: ${(props) => props.theme.colors.primary};
      text-align: center;
      color: #fff;
      transform: translate(-50%, -50%);
      h1 {
        font-size: 2.4rem;
      }
      p {
      }
      @media screen and (max-width: 850px) {
        display: none;
      }
    }
    iframe {
      width: 90rem;
      height: 50rem;
      display: block;
      padding: 1rem;
      border: 0.1rem solid #fff;
    }
    img {
      width: 100%;
      margin: 0 auto;
      display: block;
      @media screen and (min-width: 1300px) {
        display: none;
      }
    }
    @media screen and (min-width: 1300px) {
      height: 100vh;
      width: 100%;
      background: url(${background}) no-repeat center;
      background-size: cover;
    }
  }
`;

const Hero = () => {
  const { mailString } = useApplication();

  return (
    <>
      <StyledContainer>
        <div className="content">
          <div className="banner">
            <h1>
              If you are interested in joining our team, please apply below!
            </h1>
            <p>
              South Eastern, Red Rock Casino, Santa Fe Casino, Durango Resort
            </p>
            <a href="#application" className="button">
              Apply Now
            </a>
          </div>
          <img src={background} alt="Nielsens Frozen Custard" />
        </div>
      </StyledContainer>
    </>
  );
};

export default Hero;
